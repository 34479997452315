<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">Услуги</p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column prop="name" label="Наименование">
                        </el-table-column>
                        <el-table-column
                            prop="namefull"
                            label="Услуги полного имени"
                        >
                        </el-table-column>
                        <el-table-column prop="cod" label="Код">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tableData: [
                {
                    name: "Sozlash",
                    namefull: "to'liq o'rnatib berish",
                    cod: "No. 189,",
                },
                {
                    name: "Sozlash",
                    namefull: "to'liq o'rnatib berish",
                    cod: "No. 189,",
                },
                {
                    name: "Sozlash",
                    namefull: "to'liq o'rnatib berish",
                    cod: "No. 189,",
                },
                {
                    name: "Sozlash",
                    namefull: "to'liq o'rnatib berish",
                    cod: "No. 189,",
                },
            ],
        };
    },
};
</script>
